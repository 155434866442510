<template>
  <div class="text-left p-20">
    <div class="mb-40">
      <a-form-model
        ref="container"
        :model="model"
        :labelCol="{ span: 5 }"
        :wrapperCol="{ span: 11 }"
        labelAlign="left"
        :colon="false"
      >
        <!-- 1 -->
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>申报基础信息</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value0"
              label="申报人姓名"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value0"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value1"
              label="所在单位"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value1"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value2"
              label="推荐机构"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value2"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value3"
              label="申报日期"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <a-date-picker
                :disabled="disabled"
                v-model="model.value3"
              ></a-date-picker>
            </gf-form-item>
          </div>
        </div>
        <!-- 4 -->
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>个人基本情况</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="Photo"
              label="登记照（1寸免冠证件照）"
              :wrapperCol="{ span: 7 }"
              :rules="[
                {
                  required: true,
                  message: '请上传',
                  trigger: ['change', 'blur'],
                },
                { validator: validator },
              ]"
            >
              <picture-upload
                :scale="{ w: 413, h: 626 }"
                :width="280"
                :height="160"
                accept=".png,.jpg,.jpeg"
                :size="5"
                v-model="model.Photo"
              ></picture-upload>
            </gf-form-item>
            <gf-form-item
              prop="value4"
              label="姓名"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value4"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value5"
              label="性别"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <a-select v-model="model.value5">
                <a-select-option :value="1">男</a-select-option>
                <a-select-option :value="2">女</a-select-option>
              </a-select>
            </gf-form-item>
            <gf-form-item
              prop="value6"
              label="身份证"
              :wrapperCol="{ span: 7 }"
              :rules="[
                {
                  validator: validateId,
                  message: '请输入正确格式的身份证',
                  trigger: ['change', 'blur'],
                },
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value6"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value7"
              label="政治面貌"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value7"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value8"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
              label="职称"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value8"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value9"
              label="学历学位"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value9"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value10"
              label="所在单位"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value10"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value10_1"
              label="单位地址"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value10_1"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value10_2"
              label="单位邮编"
              :wrapperCol="{ span: 7 }"
              :rules="[
                {
                  validator: validateZip,
                  message: '请输入正确格式的邮政编码',
                  trigger: ['change', 'blur'],
                },
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value10_2"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value11"
              label="担任职务"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value11"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value12"
              label="现岗位或相似岗位工作年限"
              :wrapperCol="{ span: 7 }"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
            >
              <a-input-number
                :precision="0"
                :disabled="disabled"
                v-model="model.value12"
              ></a-input-number>
            </gf-form-item>
            <gf-form-item
              prop="value13"
              label="手机"
              :wrapperCol="{ span: 7 }"
              :rules="[
                {
                  validator: validateTel,
                  message: '请输入正确格式的手机号码',
                  trigger: ['change', 'blur'],
                },
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value13"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value14"
              label="邮箱"
              :wrapperCol="{ span: 7 }"
              :rules="[
                {
                  validator: validateEmail,
                  message: '请输入正确格式的邮箱',
                  trigger: ['change', 'blur'],
                },
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value14"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value15"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="教育经历"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="填写示例：2012-2016-北京大学"
                v-model="model.value15"
              ></gf-re-textarea>
            </gf-form-item>
            <gf-form-item
              prop="value16"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="工作经历"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="请在此输入工作经历"
                v-model="model.value16"
              ></gf-re-textarea>
            </gf-form-item>
            <gf-form-item
              prop="value17"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="社会团体兼职情况"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="请在此输入社会团体兼职情况"
                v-model="model.value17"
              ></gf-re-textarea>
            </gf-form-item>
            <gf-form-item
              prop="value18"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="何时何地受过何种奖励"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder=""
                v-model="model.value18"
              ></gf-re-textarea>
            </gf-form-item>
            <gf-form-item label="证实性材料" tooltip="不限于个人技术资格证书、相关技术研究或项目成果、个人获得国家及省部级以上荣誉等">
              <file-upload
                v-if="model.value22_1"
                :size="100"
                v-model="model.value22_1"
              ></file-upload>
            </gf-form-item>
          </div>
        </div>
        <!-- 16 -->
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>申报说明</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value18_1"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 3000,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="工作成绩和突出贡献"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="3000"
                placeholder="结合所申报的奖项方向，展示个人在上海市数字化转型/工业互联网/技术创新等领域中的主要工作成绩和突出贡献。"
                v-model="model.value18_1"
              ></gf-re-textarea>
            </gf-form-item>
            <gf-form-item
              prop="value18_2"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 3000,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="代表性成果"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="3000"
                placeholder="说明个人取得的成果，如取得专利、攻克难题、获得团队荣誉、成果水平、特色特点等"
                v-model="model.value18_2"
              ></gf-re-textarea>
            </gf-form-item>
          </div>
        </div>
        <!-- 20 -->
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>所在单位推荐意见</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value19"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
              label="单位联系人"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value19"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value20"
              :rules="[
                {
                  validator: validateTel,
                  message: '请输入正确格式的手机号码',
                  trigger: ['change', 'blur'],
                },
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="联系电话"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value20"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value21"
              :rules="[
                {
                  validator: validateEmail,
                  message: '请输入正确格式的邮箱',
                  trigger: ['change', 'blur'],
                },
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="邮箱"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value21"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value22"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="推荐意见"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="围绕申报人日常工作情况、工作业绩、申报材料真实性等展开"
                v-model="model.value22"
              ></gf-re-textarea>
            </gf-form-item>
          </div>
        </div>

        <!-- 23 -->
        <div>
          <div class="mb-20">
            <gf-title>主管部门/行业协会推荐意见</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value23"
              label="主管部门/行业协会名称"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value23"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value24"
              label="联系人"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value24"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              :rules="{
                validator: validateTel,
                message: '请输入正确格式的手机号码',
                trigger: ['change', 'blur'],
              }"
              prop="value25"
              label="手机"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value25"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              :rules="{
                validator: validateFax,
                message: '请输入正确格式的传真',
                trigger: ['change', 'blur'],
              }"
              prop="value26"
              label="传真"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value26"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item prop="value27" label="地址" :wrapperCol="{ span: 7 }">
              <gf-re-input
                :disabled="disabled"
                v-model="model.value27"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              :rules="{
                validator: validateZip,
                message: '请输入正确格式的邮政编码',
                trigger: ['change', 'blur'],
              }"
              prop="value28"
              label="邮编"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value28"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              :rules="{
                validator: validateEmail,
                message: '请输入正确格式的邮箱',
                trigger: ['change', 'blur'],
              }"
              prop="value28_1"
              label="邮箱"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value28_1"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value29"
              :rules="[
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="主管部门/行业协会简介"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="请在此输入主管部门/行业协会简介"
                v-model="model.value29"
              ></gf-re-textarea>
            </gf-form-item>
            <gf-form-item
              prop="value30"
              :rules="[
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="推荐意见"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="围绕申报人相关成果、行业影响力等展开"
                v-model="model.value30"
              ></gf-re-textarea>
            </gf-form-item>
          </div>
        </div>
        <!-- 31 -->
        <div>
          <div class="mb-20">
            <gf-title>专家推荐意见</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value31"
              label="专家姓名"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value31"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value32"
              label="所在单位"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value32"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item prop="value33" label="职位" :wrapperCol="{ span: 7 }">
              <gf-re-input
                :disabled="disabled"
                v-model="model.value33"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item prop="value34" label="地址" :wrapperCol="{ span: 7 }">
              <gf-re-input
                :disabled="disabled"
                v-model="model.value34"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              :rules="{
                validator: validateZip,
                message: '请输入正确格式的邮政编码',
                trigger: ['change', 'blur'],
              }"
              prop="value35"
              label="邮编"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.value35"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="value36"
              :rules="[
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="专家个人介绍"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="请在此输入专家个人简介"
                v-model="model.value36"
              ></gf-re-textarea>
            </gf-form-item>
            <gf-form-item
              prop="value37"
              :rules="[
                {
                  max: 300,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="推荐意见"
            >
              <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="300"
                placeholder="围绕申报人相关成果、行业影响力等展开"
                v-model="model.value37"
              ></gf-re-textarea>
            </gf-form-item>
          </div>
        </div>
      </a-form-model>
    </div>
    <div class="text-center">
      <slot v-bind="{ validate }" />
    </div>
  </div>
</template>

<script>
import {
  validateArrayCount,
  validateFax,
  validateEmail,
  validateTel,
  validateZip,
  validatePhone,
  validateId,
} from "@/utils/validator";
const validateSceneCount = validateArrayCount(0, 3);
export default {
  props: {
    rules: {
      type: Object,
      default: () => ({}),
    },
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    disabled: Boolean,
  },
  created() {
    this.model = { ...this.dataSource };
  },
  data() {
    return {
      model: {},
      scale: [
        { ID: 1, Name: "大型" },
        { ID: 2, Name: "中型" },
        { ID: 3, Name: "小型" },
      ],
    };
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.container.validate((valid) => {
          if (valid) {
            resolve(this.restore({ ...this.model }));
          } else {
            reject();
          }
        });
      });
    },
    restore(data) {
      const temp = {};

      temp.User = {
        Attachments: data.value22_1,
        Name: data.value4,
        Gender: data.value5,
        Identity: data.value6,
        Political: data.value7,
        Title: data.value8,
        Education: data.value9,
        Company: data.value10,
        Position: data.value11,
        WorkDuration: data.value12,
        Phone: data.value13,
        Email: data.value14,
        EduExp: data.value15,
        WorkExp: data.value16,
        SideJob: data.value17,
        Rewards: data.value18,
        Address: data.value10_1,
        Contributions: data.value18_1,
        Achievements: data.value18_2,
        Photo: data.Photo,
        Recommendation: {
          Contact: data.value19,
          Phone: data.value20,
          Email: data.value21,
          Comment: data.value22,
          ZipCode: data.value10_2,
        },
      };
      temp.Name = data.value0;
      temp.CompanyName = data.value1;
      temp.Referrer = data.value2;
      temp.Recommendation = {
        Name: data.value23,
        Contact: data.value24,
        Phone: data.value25,
        Fax: data.value26,
        Address: data.value27,
        ZipCode: data.value28,
        Email: data.value28_1,
        Intro: data.value29,
        Comment: data.value30,
      };
      temp.Expert = {
        Name: data.value31,
        Department: data.value32,
        Position: data.value33,
        Address: data.value34,
        ZipCode: data.value35,
        Intro: data.value36,
        Comment: data.value37,
      };
      temp.Time = data.value3;
      temp.Photo = data.Photo;

      return temp;
    },
    validateZip(...rest) {
      return validateZip(...rest);
    },
    validatePhone(...rest) {
      return validatePhone(...rest);
    },
    validateEmail(...rest) {
      return validateEmail(...rest);
    },
    validateTel(...rest) {
      return validateTel(...rest);
    },
    validateFax(...rest) {
      return validateFax(...rest);
    },
    validateId(...rest) {
      return validateId(...rest);
    },
    validator(rule, value, callback) {
      if (value && value.ID) {
        callback();
      } else {
        callback(new Error("请上传"));
      }
    },
  },
  watch: {
    dataSource(val) {
      const temp = Object.assign(this.model, val);
      this.model = { ...temp };
    },
  },
};
</script>