<template>
  <div class="text-left p-20">
    <div class="mb-40">
      <a-form-model
        ref="container"
        :model="model"
        :labelCol="{ span: 7 }"
        :wrapperCol="{ span: 11 }"
        labelAlign="left"
        :colon="false"
      >
        <!-- 21 -->
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>附件</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value"
              label="申报表（请上传盖章扫描pdf版本）"
              :rules="[
                {
                  required: true,
                  message: '请上传',
                  trigger: ['change', 'blur'],
                },
                { validator: validator },
              ]"
            >
              <div class="relative">
                <file-upload
                  v-if="model.value"
                  :multiple="false"
                  :size="20"
                  accept=".pdf"
                  v-model="model.value"
                  class="mr-20"
                ></file-upload>
                <a
                  @click="print"
                  class="
                    text-sm text-primary
                    absolute
                    left-0
                    top-0
                    transform
                    translate-x-1/2
                  "
                  >下载2021“工赋风云榜”年度先锋人物申报表</a
                >
              </div>
            </gf-form-item>
          </div>
        </div>
      </a-form-model>
    </div>
    <div class="text-center">
      <slot v-bind="{ validate }" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: {},
    };
  },
  created() {
    this.model = {
      value: [this.dataSource.Form],
    };
  },
  computed: {
    str() {
      return `<div style="width: 100%">
      <div style="height: 978px; page-break-after: always; font-wight: 300">
        <div style="text-align: end">编号：_____________</div>
        <h1
          style="margin: 200px 0;text-align: center;font-weight: 700; font-size: 36px; padding: 0 100px"
        >
          2021“工赋风云榜”年度先锋人物 申报表
        </h1>
        <div style="text-align: center">
        <div style="margin: 0 auto; display: inline-block; text-align: start">
        <div style="margin-bottom: 10px">申报人姓名：${
          this.dataSource.Detail.Name || ""
        }</div>
          <div style="margin-bottom: 10px">所在单位（盖章）：${
            this.dataSource.Detail.CompanyName || ""
          }</div>
          <div style="margin-bottom: 10px">推荐机构：${
            this.dataSource.Detail.Referrer || ""
          }</div>
          <div style="margin-bottom: 10px">申报日期：${
            moment(this.dataSource.Detail.Time).format("YYYY-MM-DD") || ""
          }</div>
        </div>
        </div>
        
      </div>
      <div style="height: 978px; page-break-after: always;font-weight: 300">
        <div style="font-size: 24px; font-weight: 500; text-align: center; margin-bottom: 100px">填报说明</div>
        <p style="text-indent: 2em; line-height: 36px !important; margin-bottom: 10px">
          1、 “工赋风云榜”年度先锋人物评选活动申报材料由申报表和证实性材料两部分组成，所填数据及提供资料务必真实、准确，数字及各类符号应填写正确、清楚、完整。本表封面编号由“工赋风云榜”评选活动组织委员会统一填写。
        </p>
        <p style="text-indent: 2em; line-height: 36px !important; margin-bottom: 10px">2、证实性材料包括个人技术资格证书、个人从事重要技术工作（活动）取得实效的证明性材料、相关技术研究成果、个人获得国家及省部级以上荣誉等，以复印件形式附在申报表后。</p>
        <p style="text-indent: 2em; line-height: 36px !important; margin-bottom: 10px">3、申报个人请登陆工赋风云榜评选网站（https://events.gongfuxingqiu.com/fyb2021）进行注册以及申报表的填写，并且上传PDF盖公章扫描版一份。申报人照片需为jpg格式（1张正面1寸免冠彩色证件照和1张生活照）。</p>
        <p style="text-indent: 2em; line-height: 36px !important; margin-bottom: 10px">4、纸质盖章版打印4份，快递或闪送到虹口区东大名路1098号浦江国际金融广场3楼上海市工业互联网协会，收件人：刘翊尊，13838160215</p>
        <p style="text-indent: 2em; line-height: 36px !important; margin-bottom: 10px">5、填写过程中有问题可以咨询工赋骑士刘翊尊，13838160215</p>
      </div>
      <div style=" page-break-after: always;font-weight: 300">
        <div style="font-weight: 400; margin-bottom: 10px">一、个人基本情况</div>
        <table style="border-collapse: collapse; width: 99.7768%;font-weight: 300;text-align: center;height: 100%; font-size: 16px" border="1">
        <tbody style="height: 100%">
        <tr style="page-break-inside: avoid;">
        <td style="width: 15.8167%;" colspan="2">参选赛道</td>
        <td style="width: 63.2691%;height: 50px" colspan="8">
          <input type="checkbox" ${
            this.dataSource.Detail.Track.ID === 5 ? "checked" : ""
          } /> 领军人物
                <input type="checkbox" ${
                  this.dataSource.Detail.Track.ID === 6 ? "checked" : ""
                } /> 首席数字官
                <input type="checkbox" ${
                  this.dataSource.Detail.Track.ID === 7 ? "checked" : ""
                } /> 技术专家
        </td>
        </tr>
        <tr style="page-break-inside: avoid;">
        <td style="width: 15.8167%;height: 50px" colspan="2">姓名</td>
        <td style="width: 15.8167%;" colspan="2">${
          this.dataSource.Detail.User.Name || ""
        }</td>
        <td style="width: 15.8167%;" colspan="2">身份证号</td>
        <td style="width: 15.8167%;" colspan="2">${
          this.dataSource.Detail.User.Identity || ""
        }</td>
        <td style="width: 15.8189%;" colspan="2" rowspan="5">
        <p>照片</p>
        <p><span style="font-size: 12px">（正面</span>1寸免冠彩色证件照）</span></p>
        </td>
        </tr>
        <tr >
        <td style="width: 15.8167%;height: 50px" colspan="2">性别</td>
        <td style="width: 15.8167%;" colspan="2">${
          this.dataSource.Detail.User.Gender === 1 ? "男" : "女" || ""
        }</td>
        <td style="width: 15.8167%;" colspan="2">政治面貌</td>
        <td style="width: 15.8167%;" colspan="2">${
          this.dataSource.Detail.User.Political || ""
        }</td>
        </tr>
        <tr>
        <td style="width: 15.8167%;height: 50px" colspan="2">职称</td>
        <td style="width: 15.8167%;" colspan="2">${
          this.dataSource.Detail.User.Title || ""
        }</td>
        <td style="width: 15.8167%;" colspan="2">学历学位</td>
        <td style="width: 15.8167%;" colspan="2">${
          this.dataSource.Detail.User.Education || ""
        }</td>
        </tr>
        <tr style="page-break-inside: avoid;">
        <td style="width: 15.8167%;height: 50px" colspan="2">所在单位</td>
        <td style="width: 47.4502%;" colspan="6">${
          this.dataSource.Detail.User.Company || ""
        }</td>
        </tr>
        <tr style="page-break-inside: avoid;">
        <td style="width: 15.8167%;height: 50px" colspan="2">担任职务</td>
        <td style="width: 47.4502%;" colspan="6">${
          this.dataSource.Detail.User.Position || ""
        }</td>
        </tr>
        <tr style="page-break-inside: avoid;">
        <td style="width: 39.5418%;height: 50px" colspan="5">现岗位或相似岗位工作年限</td>
        <td style="width: 39.544%;" colspan="5">${
          this.dataSource.Detail.User.WorkDuration || ""
        }</td>
        </tr>
        <tr style="page-break-inside: avoid;">
        <td style="width: 23.7251%;height: 50px" colspan="3">联系电话</td>
        <td style="width: 15.8167%;" colspan="2">${
          this.dataSource.Detail.User.Phone || ""
        }</td>
        <td style="width: 15.8167%;" colspan="2">E-mail</td>
        <td style="width: 23.7272%;" colspan="3">${
          this.dataSource.Detail.User.Email || ""
        }</td>
        </tr>
        <tr style="page-break-inside: avoid;">
        <td style="width: 23.7251%;height: 50px" colspan="3">单位地址及邮编</td>
        <td style="width: 55.3607%;" colspan="7">${
          this.dataSource.Detail.User.Address
        }${
        this.dataSource.Detail.User.Address &&
        this.dataSource.Detail.User.Recommendation.ZipCode
          ? " - "
          : ""
      }${this.dataSource.Detail.User.Recommendation.ZipCode || ""}</td>
        </tr>
        <tr style="height: 150px;page-break-inside: avoid;">
        <td style="width: 23.7251%;" colspan="3">教育经历</td>
        <td style="width: 55.3607%;text-align: start;padding:10px 10px" colspan="7">${
          this.dataSource.Detail.User.EduExp?this.dataSource.Detail.User.EduExp.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""
        }</td>
        </tr>
        <tr style="height: 150px;page-break-inside: avoid;">
        <td style="width: 23.7251%;" colspan="3">工作经历</td>
        <td style="width: 55.3607%;text-align: start;padding:10px 10px" colspan="7">${
          this.dataSource.Detail.User.WorkExp?this.dataSource.Detail.User.WorkExp.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""
        }</td>
        </tr>
        <tr style="height: 150px;page-break-inside: avoid;">
        <td style="width: 23.7251%;" colspan="3">社会团体兼职情况</td>
        <td style="width: 55.3607%;text-align: start;padding:10px 10px" colspan="7">${
          this.dataSource.Detail.User.SideJob?this.dataSource.Detail.User.SideJob.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""
        }</td>
        </tr>
        <tr style="height: 150px;page-break-inside: avoid;">
        <td style="width: 23.7251%;" colspan="3">何时何地受过何种奖励</td>
        <td style="width: 7.90836%;text-align: start;padding:10px 10px" colspan="7">${
          this.dataSource.Detail.User.Rewards?this.dataSource.Detail.User.Rewards.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""
        }</td>
        </tr>
        <tr style="height: 50px;page-break-inside: avoid;text-align: start">
          <td colspan="10">
            <div style="margin-bottom: 10px; text-align: start">结合所申报的奖项方向，展示个人在上海市数字化转型/工业互联网/技术创新等领域中的主要工作成绩和突出贡献。</div>
            ${this.dataSource.Detail.User.Contributions?this.dataSource.Detail.User.Contributions.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""}
          </td>
        </tr>
        <tr style="height: 50px;page-break-inside: avoid;text-align: start">
          <td colspan="10">
            <div style="margin-bottom: 10px; text-align: start">本次申报代表性成果情况说明：（说明个人取得的成果，如取得专利、攻克难题、获得团队荣誉、成果水平、特色特点等）</div>
            ${this.dataSource.Detail.User.Achievements?this.dataSource.Detail.User.Achievements.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""}
          </td>
        </tr>
        <tr style="display:none">
        <td style="width: 7.90836%;"></td>
        <td style="width: 7.90836%;"></td>
        <td style="width: 7.90836%;"></td>
        <td style="width: 7.90836%;"></td>
        <td style="width: 7.90836%;"></td>
        <td style="width: 7.90836%;"></td>
        <td style="width: 7.90836%;"></td>
        <td style="width: 7.90836%;"></td>
        <td style="width: 7.90836%;"></td>
        <td style="width: 7.91053%;"></td>
        </tr>
        </tbody>
        </table>
      </div>
      <div style="min-height: 978px; page-break-after: always;font-weight: 300">
        <div style="font-weight: 400;margin-bottom: 10px">二、所在单位推荐意见</div>
        <table
          style="border-collapse: collapse; width: 99.7768%; border-color: #000; height: 100%; text-align:center;font-weight: 300; font-size: 16px"
          border="1"
        >
          <tbody style="height: 100%">
            <tr style="height: 50px">
              <td style="width: 36%;height: 50px">单位联系人</td>
              <td style="width: 64%">${
                this.dataSource.Detail.User.Recommendation.Contact || ""
              }</td>
            </tr>
            <tr style="height: 50px">
              <td style="width: 36%;height: 50px">联系电话</td>
              <td style="width: 64%">${
                this.dataSource.Detail.User.Recommendation.Phone || ""
              }</td>
            </tr>
            <tr style="height: 50px">
              <td style="width: 36%;height: 50px">邮箱</td>
              <td style="width: 64%">${
                this.dataSource.Detail.User.Recommendation.Email
              } </td>
            </tr>
            <tr style="height: 800px">
              <td style="width: 36%">推荐意见</td>
              <td style="width: 64%;font-size: 16px">
                <div style="height:80%;text-align:start;padding:10px 10px">${
                  this.dataSource.Detail.User.Recommendation.Comment?this.dataSource.Detail.User.Recommendation.Comment.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""
                }</div>
                <div style="margin-bottom:10px">
                    <div style="width:70%;text-align: end">工作单位（盖章）：</div>
                </div>
                <div style="margin-bottom:10px">
                    <div style="width:70%;text-align: end">负责人（签字）：</div>
                </div>
                <div style="justify-content: end;display: flex;padding-right:20px">
                    <div style="width: 10%"></div>年
                    <div style="width: 10%"></div>月
                    <div style="width: 10%"></div>日
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="min-height: 978px; page-break-after: always;font-weight: 300"">
        <div style="font-weight: 400; margin-bottom: 10px">三、主管部门/行业协会推荐意见（如有）</div>
        <table
          style="border-collapse: collapse; width: 99.7768%; border-color: #000; height: 100%; text-align:center;font-weight: 300; font-size: 16px;line-height: 1.5"
          border="1"
        >
          <tbody style="height: 100%">
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%;style="height: 50px"">主管部门/行业协会名称</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Recommendation.Name || ""
              }</td>
            </tr>
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%">联系电话及联系人</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Recommendation.Contact || ""
              }${
        this.dataSource.Detail.Recommendation.Contact &&
        this.dataSource.Detail.Recommendation.Phone
          ? " - "
          : ""
      }${this.dataSource.Detail.Recommendation.Phone || ""}</td>
            </tr>
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%">联系传真及E-mail</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Recommendation.Fax || ""
              }${
        this.dataSource.Detail.Recommendation.Fax &&
        this.dataSource.Detail.Recommendation.Email
          ? " - "
          : ""
      }${this.dataSource.Detail.Recommendation.Email || ""}</td>
            </tr>
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%">联系地址及邮编</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Recommendation.Address || ""
              }${
        this.dataSource.Detail.Recommendation.Address &&
        this.dataSource.Detail.Recommendation.ZipCode
          ? " - "
          : ""
      }${this.dataSource.Detail.Recommendation.ZipCode || ""}</td>
            </tr>
            <tr style="height: 300px;page-break-inside: avoid;">
              <td style="width: 36%;">
                <div>主管部门/行业协会简介</div>
              </td>
              <td style="width: 64%; text-align: start;padding: 10px;height: 200px">${
                this.dataSource.Detail.Recommendation.Intro
                  ? this.dataSource.Detail.Recommendation.Intro.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""
              }</td>
            </tr>
            <tr style="height: 500px;page-break-inside: avoid;">
              <td style="width: 36%">推荐意见</td>
              <td style="width: 64%;font-size: 16px;line-height: 1.5">
                <div style="min-height:70%;text-align: start;padding: 10px">${
                  this.dataSource.Detail.Recommendation.Comment
                    ? this.dataSource.Detail.Recommendation.Comment.replace(
                        / /g,
                        "&nbsp;"
                      ).replace(/\n/g, "<br />")
                    : ""
                }</div>
                <div style="margin-bottom:10px">
                    <div style="width:70%;text-align: end">主管部门/行业协会（盖章）：</div>
                </div>
                <div style="margin-bottom:10px">
                    <div style="width:70%;text-align: end">负责人（签字）：</div>
                </div>
                <div style="justify-content: end;display: flex;padding-right:20px">
                    <div style="width: 10%"></div>年
                    <div style="width: 10%"></div>月
                    <div style="width: 10%"></div>日
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="min-height: 978px; page-break-after: always;font-weight: 300">
        <div style="font-weight: 400;margin-bottom: 10px">四、专家推荐意见（如有）</div>
        <table
          style="border-collapse: collapse; width: 99.7768%; border-color: #000; height: 100%; text-align:center;font-weight: 300; font-size: 16px;line-height: 1.5"
          border="1"
        >
          <tbody style="height: 100%">
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%">专家姓名</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Expert.Name || ""
              }</td>
            </tr>
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%">单位及职位</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Expert.Department || ""
              }${
        this.dataSource.Detail.Expert.Department &&
        this.dataSource.Detail.Expert.Position
          ? " - "
          : ""
      }${this.dataSource.Detail.Expert.Position || ""}</td>
            </tr>
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%">联系地址及邮编</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Expert.Address || ""
              }${
        this.dataSource.Detail.Expert.Address &&
        this.dataSource.Detail.Expert.ZipCode
          ? " - "
          : ""
      }${this.dataSource.Detail.Expert.ZipCode || ""}</td>
            </tr>
            <tr style="height: 300px;page-break-inside: avoid;">
              <td style="width: 36%">
                <div>专家个人介绍</div>
              </td>
              <td style="width: 64%;text-align: start;padding: 10px">${
                this.dataSource.Detail.Expert.Intro
                  ? this.dataSource.Detail.Expert.Intro.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""
              }</td>
            </tr>
            <tr style="height: 500px;page-break-inside: avoid;">
              <td style="width: 36%">推荐意见</td>
              <td style="width: 64%;font-size: 16px;line-height: 1.5">
                <div style="height:80%;text-align: start;padding: 10px">${
                  this.dataSource.Detail.Expert.Comment
                    ? this.dataSource.Detail.Expert.Comment.replace(
                        / /g,
                        "&nbsp;"
                      ).replace(/\n/g, "<br />")
                    : ""
                }</div>
                <div style="margin-bottom:10px">
                    <div style="width:70%;text-align: end">专家（签字）：</div>
                </div>
                <div style="justify-content: end;display: flex;padding-right:20px">
                    <div style="width: 10%"></div>年
                    <div style="width: 10%"></div>月
                    <div style="width: 10%"></div>日
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>`;
    },
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.container.validate((valid) => {
          const data = this.model.value.filter((item) => item);
          if (valid && data.length && data[0].ID) {
            resolve({ ...this.model.value[0] });
          } else {
            reject();
          }
        });
      });
    },
    print() {
      const el = this.str;
      const iframe = document.createElement("IFRAME");
      window.document.body.append(iframe);
      iframe.setAttribute(
        "style",
        "position: absolute;left:-1000px;top:-1000px"
      );
      const doc = iframe.contentDocument;

      const info = this.$store.state.userInfo;
      const company = this.$store.state.companyInfo;
      const detail = this.dataSource.Detail || {};
      const temp = {
        uid: info.UID || info.id,
        type: "click",
        name: "download",
        metadata: {
          company: company.companyName,
          track: detail.Track.Name,
        },
      };
      this.$store.dispatch("track/setTrack", temp);

      doc.write(el);
      doc.close();
      iframe.contentWindow.focus();
      const title = window.document.title;
      window.document.title = "2021“工赋风云榜”年度先锋人物申报表";
      iframe.contentWindow.print();
      window.document.title = title;
      window.document.body.removeChild(window.document.querySelector("iframe"));
    },
    validator(rule, value, callback) {
      callback();
    },
  },
};
</script>