var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"absolute top-0 left-0 w-full",staticStyle:{"height":"200px","background-image":"linear-gradient(to bottom, #f7fbff, #fff)"}}),_c('div',{staticClass:"text-left w-full relative z-10"},[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"1200px"}},[_c('div',{staticClass:"mb-40",staticStyle:{"padding":"65px 0"}},[_c('div',{staticClass:"text-28 mb-10 font-bold",staticStyle:{"color":"#008dfc"}},[_vm._v(" 我要"+_vm._s(this.track ? "修改" : "参赛")+" - "+_vm._s(_vm.trackName.Name || "")+" ")]),_vm._m(0)]),_c('div',{staticClass:"mb-40"},[([1, 2, 3, 4].includes(_vm.trackName.ID))?_c('div',[(_vm.step === 1)?_c('ApplyForm',{attrs:{"dataSource":_vm.current},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('a-space',{attrs:{"size":20}},[_c('a-button',{attrs:{"size":"large","type":"primary","ghost":""},on:{"click":_vm.back}},[_vm._v("取消")]),_c('a-button',{attrs:{"size":"large","type":"primary"},on:{"click":function($event){return _vm.next(validate)}}},[_vm._v(" 下一步 ")])],1)}}],null,false,2575138716)}):(_vm.step === 2)?_c('PrintForm',{attrs:{"dataSource":_vm.data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('a-space',{attrs:{"size":20}},[_c('a-button',{attrs:{"size":"large","type":"primary","ghost":""},on:{"click":_vm.backStep}},[_vm._v(" 上一步 ")]),_c('a-button',{attrs:{"size":"large","type":"primary","ghost":""},on:{"click":function($event){return _vm.save(validate)}}},[_vm._v(" 保存草稿 ")]),_c('a-button',{attrs:{"size":"large","type":"primary"},on:{"click":function($event){return _vm.submitForm(validate)}}},[_vm._v(" 提交 ")])],1)}}],null,false,3662739161)}):_vm._e()],1):_vm._e(),([5, 6, 7].includes(_vm.trackName.ID))?_c('div',[(_vm.step === 1)?_c('ApplyForm1',{attrs:{"dataSource":_vm.current},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('a-space',{attrs:{"size":20}},[_c('a-button',{attrs:{"size":"large","type":"primary","ghost":""},on:{"click":_vm.back}},[_vm._v("取消")]),_c('a-button',{attrs:{"size":"large","type":"primary"},on:{"click":function($event){return _vm.next(validate)}}},[_vm._v(" 下一步 ")])],1)}}],null,false,2575138716)}):(_vm.step === 2)?_c('PrintForm1',{attrs:{"dataSource":_vm.data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('a-space',{attrs:{"size":20}},[_c('a-button',{attrs:{"size":"large","type":"primary","ghost":""},on:{"click":_vm.backStep}},[_vm._v(" 上一步 ")]),_c('a-button',{attrs:{"size":"large","type":"primary","ghost":""},on:{"click":function($event){return _vm.save(validate)}}},[_vm._v(" 保存草稿 ")]),_c('a-button',{attrs:{"size":"large","type":"primary"},on:{"click":function($event){return _vm.submitForm(validate)}}},[_vm._v(" 提交 ")])],1)}}],null,false,3662739161)}):_vm._e()],1):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-666"},[_vm._v(" 请您详细填写资料哦，方案越详细获奖的概率更大哦！"),_c('span',{staticClass:"text-xs"},[_vm._v("（"),_c('span',{staticClass:"text-primary"},[_vm._v("注")]),_vm._v("：1、资料提交后如需修改可在个人中心进行修改；2、需要上传盖章版申请表后，才能成功提交哦！）")])])}]

export { render, staticRenderFns }