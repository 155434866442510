<template>
  <div class="relative">
    <div
      class="absolute top-0 left-0 w-full"
      style="
        height: 200px;
        background-image: linear-gradient(to bottom, #f7fbff, #fff);
      "
    ></div>
    <div class="text-left w-full relative z-10">
      <div class="mx-auto" style="width: 1200px">
        <div class="mb-40" style="padding: 65px 0">
          <div class="text-28 mb-10 font-bold" style="color: #008dfc">
            我要{{ this.track ? "修改" : "参赛" }} - {{ trackName.Name || "" }}
          </div>
          <div class="text-666">
            请您详细填写资料哦，方案越详细获奖的概率更大哦！<span
              class="text-xs"
              >（<span class="text-primary">注</span
              >：1、资料提交后如需修改可在个人中心进行修改；2、需要上传盖章版申请表后，才能成功提交哦！）</span
            >
          </div>
        </div>
        <div class="mb-40">
          <div v-if="[1, 2, 3, 4].includes(trackName.ID)">
            <ApplyForm :dataSource="current" v-if="step === 1">
              <a-space :size="20" slot-scope="{ validate }">
                <a-button size="large" type="primary" ghost @click="back"
                  >取消</a-button
                >

                <a-button size="large" type="primary" @click="next(validate)">
                  下一步
                </a-button>
              </a-space>
            </ApplyForm>
            <PrintForm :dataSource="data" v-else-if="step === 2">
              <a-space :size="20" slot-scope="{ validate }">
                <a-button size="large" type="primary" ghost @click="backStep">
                  上一步
                </a-button>
                <a-button
                  size="large"
                  type="primary"
                  ghost
                  @click="save(validate)"
                >
                  保存草稿
                </a-button>
                <a-button
                  size="large"
                  type="primary"
                  @click="submitForm(validate)"
                >
                  提交
                </a-button>
              </a-space>
            </PrintForm>
          </div>
          <div v-if="[5, 6, 7].includes(trackName.ID)">
            <ApplyForm1 :dataSource="current" v-if="step === 1">
              <a-space :size="20" slot-scope="{ validate }">
                <a-button size="large" type="primary" ghost @click="back"
                  >取消</a-button
                >

                <a-button size="large" type="primary" @click="next(validate)">
                  下一步
                </a-button>
              </a-space>
            </ApplyForm1>
            <PrintForm1 :dataSource="data" v-else-if="step === 2">
              <a-space :size="20" slot-scope="{ validate }">
                <a-button size="large" type="primary" ghost @click="backStep">
                  上一步
                </a-button>
                <a-button
                  size="large"
                  type="primary"
                  ghost
                  @click="save(validate)"
                >
                  保存草稿
                </a-button>
                <a-button
                  size="large"
                  type="primary"
                  @click="submitForm(validate)"
                >
                  提交
                </a-button>
              </a-space>
            </PrintForm1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApplyForm from "./components/applyForm.vue";
import ApplyForm1 from "./components/applyForm1.vue";
import PrintForm from "./components/PrintForm.vue";
import PrintForm1 from "./components/PrintForm1.vue";
import { transformCompany } from "@/utils/transform/company.js";
import { transformAttachment } from "@/utils/transform/_util";
import {
  submitReg,
  updateReg,
  submitForm,
  getEvent,
  submit,
} from "@/api1/track.js";
import { saveCompanyRecoginze } from "@/api";
import moment from "moment";
export default {
  components: { ApplyForm, PrintForm, ApplyForm1, PrintForm1 },
  data() {
    return {
      visible: false,
      current: {},
      query: {},
      step: 1,
      origin: {},
      Form: {},
    };
  },
  created() {
    this.query = this.$route.query;
    if (this.isLogin) {
      getEvent().then(() => {
        this.$store.dispatch("getReg");
      });
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    trackName() {
      if (this.query.track) {
        const track = this.options.Track.find(
          (item) => item.ID === +this.query.track
        );
        return track;
      } else {
        return {};
      }
    },
    reg() {
      return this.$store.state.reg || [];
    },
    isLogin() {
      return this.$store.state.userInfo && this.$store.state.userInfo.id;
    },
    track() {
      return this.reg.find(
        (track) => track.Detail.Track.ID === +this.query.track
      );
    },
    companyInfo() {
      return this.$store.state.user.COMPANY_INFO;
    },
    company() {
      return transformCompany(this.companyInfo);
    },
    data() {
      return { Detail: this.origin, Form: this.Form };
    },
    data1() {
      return this.track || this.company;
    },
  },
  methods: {
    back() {
      this.$router.back();
    },
    save(validate) {
      validate()
        .then((res) => {
          const track = this.track ? +this.query.track : "";
          if (track) {
            res.track_id = this.track.ID;
            this.origin.track_id = this.track.ID;
            return updateReg(this.origin).then(() => {
              if (res.ID) {
                submitForm(res).then(() => {
                  this.$store.dispatch("getReg");
                  this.$router.push("/");
                  this.$store.commit("setNotTip", true);
                });
              } else {
                submitForm(res);
                this.$store.dispatch("getReg");
                this.$router.push("/");
                this.$message.warn(
                  "您还未上传附件哦（请将报名信息导出加盖公章后上传）上传后即表示成功报名！"
                );
                this.$store.commit("setNotTip", true);
              }
            });
          } else {
            return submitReg(this.origin).then(({ data }) => {
              res.track_id = data.ID;
              if (res.ID) {
                submitForm(res).then(() => {
                  this.$store.dispatch("getReg");
                  this.$router.push("/");
                  this.$store.commit("setNotTip", true);
                  this.$message.success("保存草稿成功");
                });
              } else {
                submitForm(res);
                this.$store.dispatch("getReg");
                this.$router.push("/");
                this.$message.warn(
                  "您还未上传附件哦（请将报名信息导出加盖公章后上传）上传后即表示成功报名！"
                );
                this.$store.commit("setNotTip", true);
              }
            });
          }
        })
        .catch(() => {
          const track = this.track ? +this.query.track : "";
          if (track) {
            this.origin.track_id = this.track.ID;
            updateReg(this.origin).then(() => {
              submitForm({ track_id: this.track.ID });
              this.$store.dispatch("getReg");
              this.$router.push("/");
              this.$message.warn(
                "您还未上传附件哦（请将报名信息导出加盖公章后上传）上传后即表示成功报名！"
              );
              this.$store.commit("setNotTip", true);
            });
          } else {
            submitReg(this.origin).then(() => {
              this.$store.dispatch("getReg");
              this.$router.push("/");
              this.$message.warn(
                "您还未上传附件哦（请将报名信息导出加盖公章后上传）上传后即表示成功报名！"
              );
              this.$store.commit("setNotTip", true);
            });
          }
        })
        .finally(() => {
          const logo = this.origin.logo;
          const Info = this.companyInfo.Detail.Info;
          Info.Logo = logo;
          saveCompanyRecoginze({ Info });
        });
    },
    next(validate) {
      // this.step = 2;
      // this.origin = { Track: this.trackName };
      validate().then((res) => {
        res.Track = this.trackName;
        this.origin = res;
        this.step = 2;
      });
    },
    backStep() {
      this.step = 1;
    },
    submitForm(validate) {
      validate()
        .then((res) => {
          const track = this.track ? +this.query.track : "";
          if (track) {
            res.track_id = this.track.ID;
            this.origin.track_id = this.track.ID;
            return updateReg(this.origin).then(() => {
              if (res.ID) {
                submitForm(res).then(() => {
                  const track_id = this.track.ID;
                  submit(this.origin).then(() => {
                    this.$message.success("提交申请表成功");
                    this.$store.dispatch("getReg");
                    this.$router.push("/");
                    this.$store.commit("setNotTip", true);
                    const logo = this.origin.logo;
                      const Info = this.companyInfo.Detail.Info;
                      Info.Logo = logo;
                      saveCompanyRecoginze({ Info });
                  });
                });
              } else {
                const track_id = this.track.ID;
                submit(this.origin).then(() => {
                  this.$message.success("提交申请表成功");
                  this.$router.push("/");
                  this.$store.dispatch("getReg");
                  this.$store.commit("setNotTip", true);
                  const logo = this.origin.logo;
                    const Info = this.companyInfo.Detail.Info;
                    Info.Logo = logo;
                    saveCompanyRecoginze({ Info });
                });
              }
            });
          } else {
            return submitReg(this.origin).then(({ data }) => {
              const track_id = data.ID;
              this.origin.track_id = track_id;
              if (res.ID) {
                res.track_id = track_id;

                submitForm(res).then(() => {
                  submit(this.origin).then(() => {
                    this.$message.success("提交申请表成功");
                    this.$store.dispatch("getReg");
                    this.$router.push("/");
                    this.$store.commit("setNotTip", true);
                    const logo = this.origin.logo;
                      const Info = this.companyInfo.Detail.Info;
                      Info.Logo = logo;
                      saveCompanyRecoginze({ Info });
                  });
                });
              } else {
                submit(this.origin).then(() => {
                  this.$message.success("提交申请表成功");
                  this.$router.push("/");
                  this.$store.dispatch("getReg");
                  this.$store.commit("setNotTip", true);
                  const logo = this.origin.logo;
                    const Info = this.companyInfo.Detail.Info;
                    Info.Logo = logo;
                    saveCompanyRecoginze({ Info });
                });
              }
            });
          }
        })
        .catch(() => {
          this.$message.warn("请上传盖章扫描pdf版本申请表");
        });
    },
  },
  watch: {
    data1(val) {
      let temp;
      const hasWrire = this.reg.find((track) =>
        [1, 2, 3, 4].includes(track.Detail.Track.ID)
      );
      const hasWrire2 = this.reg.find((track) =>
        [5, 6, 7].includes(track.Detail.Track.ID)
      );

      if ([1, 2, 3, 4].includes(this.trackName.ID)) {
        if (this.origin.Recommendation) {
          const detail = this.origin || {},
            company = detail.Company || {},
            contact = company.Contact || {},
            recommend = detail.Recommendation || {},
            expert = detail.Expert || {},
            Property = company.Property || {},
            Scale = company.Scale || {};
          temp = {
            logo: this.company.logo,
            value: transformAttachment(val.Form),
            value1: detail.CompanyName,
            value2: detail.Referrer,
            value3: moment(detail.Time),
            value4: company.Name,
            value5: moment(company.RegisterTime),
            value6: Property.ID,
            value7: Scale.ID,
            value8: company.Employees,
            value9: +Boolean(company.Listed),
            value10: company.RegisterAddress,
            value11: company.Revenue2020,
            value12: company.Revenue2021,
            value13: company.Website,
            value14: company.Product,
            value15: company.Financing,
            value16: contact.Name,
            value17: contact.Phone,
            value18: contact.Position,
            value19: contact.Email,
            value20: company.Reason,
            value21: company.Plan,
            value22: transformAttachment(company.Attachments) || [],
            value23: recommend.Name,
            value24: recommend.Contact,
            value25: recommend.Phone,
            value26: recommend.Fax,
            value27: recommend.Address,
            value28: recommend.ZipCode,
            value28_1: recommend.Email,
            value29: recommend.Intro,
            value30: recommend.Comment,
            value31: expert.Name,
            value32: expert.Department,
            value33: expert.Position,
            value34: expert.Address,
            value35: expert.ZipCode,
            value36: expert.Intro,
            value37: expert.Comment,
          };
        } else if (this.track) {
          const detail = this.track.Detail || {},
            company = detail.Company || {},
            contact = company.Contact || {},
            recommend = detail.Recommendation || {},
            expert = detail.Expert || {},
            Property = company.Property || {},
            Scale = company.Scale || {};
          temp = {
            logo: this.company.logo,
            value: transformAttachment(val.Form),
            value1: detail.CompanyName,
            value2: detail.Referrer,
            value3: moment(detail.Time),
            value4: company.Name,
            value5: moment(company.RegisterTime),
            value6: Property.ID,
            value7: Scale.ID,
            value8: company.Employees,
            value9: +Boolean(company.Listed),
            value10: company.RegisterAddress,
            value11: company.Revenue2020,
            value12: company.Revenue2021,
            value13: company.Website,
            value14: company.Product,
            value15: company.Financing,
            value16: contact.Name,
            value17: contact.Phone,
            value18: contact.Position,
            value19: contact.Email,
            value20: company.Reason,
            value21: company.Plan,
            value22: transformAttachment(company.Attachments) || [],
            value23: recommend.Name,
            value24: recommend.Contact,
            value25: recommend.Phone,
            value26: recommend.Fax,
            value27: recommend.Address,
            value28: recommend.ZipCode,
            value28_1: recommend.Email,
            value29: recommend.Intro,
            value30: recommend.Comment,
            value31: expert.Name,
            value32: expert.Department,
            value33: expert.Position,
            value34: expert.Address,
            value35: expert.ZipCode,
            value36: expert.Intro,
            value37: expert.Comment,
          };
        } else if (hasWrire) {
          const detail = hasWrire.Detail || {},
            company = detail.Company || {},
            contact = company.Contact || {},
            Property = company.Property || {},
            Scale = company.Scale || {};
          temp = {
            logo: this.company.logo,
            value1: detail.CompanyName,
            value2: detail.Referrer,
            value3: moment(new Date()),
            value4: company.Name,
            value5: moment(company.RegisterTime),
            value6: Property.ID,
            value7: Scale.ID,
            value8: company.Employees,
            value9: +Boolean(company.Listed),
            value10: company.RegisterAddress,
            value11: company.Revenue2020,
            value12: company.Revenue2021,
            value13: company.Website,
            value14: company.Product,
            value15: company.Financing,
            value16: contact.Name,
            value17: contact.Phone,
            value18: contact.Position,
            value19: contact.Email,
            value22: [],
          };
        } else if (this.company) {
          temp = {
            logo: this.company.logo,
            value1: val.companyName,
            value3: moment(new Date()),
            value4: val.companyName,
            value5: moment(val.RegisterAt),
            value9: +Boolean(val.Listed),
            value10: val.registerAddress,
            value13: val.Website,
            value22: [],
          };
        }
      } else if ([5, 6, 7].includes(this.trackName.ID)) {
        if (this.origin.Recommendation) {
          const detail = this.origin || {},
            user = detail.User || {},
            companyRecommend = user.Recommendation || {},
            recommend = detail.Recommendation || {},
            expert = detail.Expert || {};

          temp = {
            Photo: transformAttachment(user.Photo),
            value: transformAttachment(val.Form),
            value0: detail.Name,
            value1: detail.CompanyName,
            value2: detail.Referrer,
            value3: moment(detail.Time),
            value4: user.Name,
            value5: user.Gender,
            value6: user.Identity,
            value7: user.Political,
            value8: user.Title,
            value9: user.Education,
            value10: user.Company,
            value10_1: user.Address,
            value10_2: companyRecommend.ZipCode,
            value22_1: transformAttachment(user.Attachments) || [],
            value11: user.Position,
            value12: user.WorkDuration,
            value13: user.Phone,
            value14: user.Email,
            value15: user.EduExp,
            value16: user.WorkExp,
            value17: user.SideJob,
            value18: user.Rewards,
            value18_1: user.Contributions,
            value18_2: user.Achievements,
            value19: companyRecommend.Contact,
            value20: companyRecommend.Phone,
            value21: companyRecommend.Email,
            value22: companyRecommend.Comment,
            value23: recommend.Name,
            value24: recommend.Contact,
            value25: recommend.Phone,
            value26: recommend.Fax,
            value27: recommend.Address,
            value28: recommend.ZipCode,
            value28_1: recommend.Email,
            value29: recommend.Intro,
            value30: recommend.Comment,
            value31: expert.Name,
            value32: expert.Department,
            value33: expert.Position,
            value34: expert.Address,
            value35: expert.ZipCode,
            value36: expert.Intro,
            value37: expert.Comment,
          };
        } else if (this.track) {
          const detail = val.Detail || {},
            user = detail.User || {},
            companyRecommend = user.Recommendation || {},
            recommend = detail.Recommendation || {},
            expert = detail.Expert || {};

          temp = {
            Photo: transformAttachment(user.Photo),
            value: transformAttachment(val.Form),
            value0: detail.Name,
            value1: detail.CompanyName,
            value2: detail.Referrer,
            value3: moment(detail.Time),
            value4: user.Name,
            value5: user.Gender,
            value6: user.Identity,
            value7: user.Political,
            value8: user.Title,
            value9: user.Education,
            value10: user.Company,
            value10_1: user.Address,
            value10_2: companyRecommend.ZipCode,
            value11: user.Position,
            value12: user.WorkDuration,
            value13: user.Phone,
            value14: user.Email,
            value15: user.EduExp,
            value16: user.WorkExp,
            value17: user.SideJob,
            value18: user.Rewards,
            value18_1: user.Contributions,
            value18_2: user.Achievements,
            value19: companyRecommend.Contact,
            value20: companyRecommend.Phone,
            value21: companyRecommend.Email,
            value22: companyRecommend.Comment,
            value22_1: transformAttachment(user.Attachments) || [],
            value23: recommend.Name,
            value24: recommend.Contact,
            value25: recommend.Phone,
            value26: recommend.Fax,
            value27: recommend.Address,
            value28: recommend.ZipCode,
            value28_1: recommend.Email,
            value29: recommend.Intro,
            value30: recommend.Comment,
            value31: expert.Name,
            value32: expert.Department,
            value33: expert.Position,
            value34: expert.Address,
            value35: expert.ZipCode,
            value36: expert.Intro,
            value37: expert.Comment,
          };
        } else if (hasWrire2) {
          const detail = hasWrire2.Detail || {},
            user = detail.User || {},
            companyRecommend = user.Recommendation || {};
          temp = {
            value1: detail.CompanyName,
            value3: moment(new Date()),
            value10: user.Company,
            value10_1: user.Address,
            value10_2: companyRecommend.ZipCode,
            value22_1: [],
          };
        } else if (this.company) {
          temp = {
            value: transformAttachment(val.Form),
            value1: val.companyName,
            value3: moment(new Date()),
            value10: val.companyName,
            value22_1: [],
          };
        }
      }
      this.Form = temp.value;
      this.current = temp;
    },
    step(val1) {
      if (val1 === 1) {
        let temp;
        const val = this.data;
        const hasWrire = this.reg.find((track) =>
          [1, 2, 3, 4].includes(track.Detail.Track.ID)
        );
        const hasWrire2 = this.reg.find((track) =>
          [5, 6, 7].includes(track.Detail.Track.ID)
        );

        if ([1, 2, 3, 4].includes(this.trackName.ID)) {
          if (this.origin.Recommendation) {
            const detail = this.origin || {},
              company = detail.Company || {},
              contact = company.Contact || {},
              recommend = detail.Recommendation || {},
              expert = detail.Expert || {},
              Property = company.Property || {},
              Scale = company.Scale || {};
            temp = {
              logo: this.company.logo,
              value: transformAttachment(val.Form),
              value1: detail.CompanyName,
              value2: detail.Referrer,
              value3: moment(detail.Time),
              value4: company.Name,
              value5: moment(company.RegisterTime),
              value6: Property.ID,
              value7: Scale.ID,
              value8: company.Employees,
              value9: +Boolean(company.Listed),
              value10: company.RegisterAddress,
              value11: company.Revenue2020,
              value12: company.Revenue2021,
              value13: company.Website,
              value14: company.Product,
              value15: company.Financing,
              value16: contact.Name,
              value17: contact.Phone,
              value18: contact.Position,
              value19: contact.Email,
              value20: company.Reason,
              value21: company.Plan,
              value22: transformAttachment(company.Attachments) || [],
              value23: recommend.Name,
              value24: recommend.Contact,
              value25: recommend.Phone,
              value26: recommend.Fax,
              value27: recommend.Address,
              value28: recommend.ZipCode,
              value28_1: recommend.Email,
              value29: recommend.Intro,
              value30: recommend.Comment,
              value31: expert.Name,
              value32: expert.Department,
              value33: expert.Position,
              value34: expert.Address,
              value35: expert.ZipCode,
              value36: expert.Intro,
              value37: expert.Comment,
            };
          } else if (this.track) {
            const detail = val.Detail || {},
              company = detail.Company || {},
              contact = company.Contact || {},
              recommend = detail.Recommendation || {},
              expert = detail.Expert || {},
              Property = company.Property || {},
              Scale = company.Scale || {};
            temp = {
              logo: this.company.logo,
              value: transformAttachment(val.Form),
              value1: detail.CompanyName,
              value2: detail.Referrer,
              value3: moment(detail.Time),
              value4: company.Name,
              value5: moment(company.RegisterTime),
              value6: Property.ID,
              value7: Scale.ID,
              value8: company.Employees,
              value9: +Boolean(company.Listed),
              value10: company.RegisterAddress,
              value11: company.Revenue2020,
              value12: company.Revenue2021,
              value13: company.Website,
              value14: company.Product,
              value15: company.Financing,
              value16: contact.Name,
              value17: contact.Phone,
              value18: contact.Position,
              value19: contact.Email,
              value20: company.Reason,
              value21: company.Plan,
              value22: transformAttachment(company.Attachments) || [],
              value23: recommend.Name,
              value24: recommend.Contact,
              value25: recommend.Phone,
              value26: recommend.Fax,
              value27: recommend.Address,
              value28: recommend.ZipCode,
              value28_1: recommend.Email,
              value29: recommend.Intro,
              value30: recommend.Comment,
              value31: expert.Name,
              value32: expert.Department,
              value33: expert.Position,
              value34: expert.Address,
              value35: expert.ZipCode,
              value36: expert.Intro,
              value37: expert.Comment,
            };
          } else if (hasWrire) {
            const detail = hasWrire.Detail || {},
              company = detail.Company || {},
              contact = company.Contact || {},
              Property = company.Property || {},
              Scale = company.Scale || {};
            temp = {
              logo: this.company.logo,
              value1: detail.CompanyName,
              value2: detail.Referrer,
              value4: company.Name,
              value5: moment(company.RegisterTime),
              value6: Property.ID,
              value7: Scale.ID,
              value8: company.Employees,
              value9: +Boolean(company.Listed),
              value10: company.RegisterAddress,
              value11: company.Revenue2020,
              value12: company.Revenue2021,
              value13: company.Website,
              value14: company.Product,
              value15: company.Financing,
              value16: contact.Name,
              value17: contact.Phone,
              value18: contact.Position,
              value19: contact.Email,
              value22: [],
            };
          } else if (this.company) {
            temp = {
              logo: this.company.logo,
              value1: val.companyName,
              value4: val.companyName,
              value5: moment(val.RegisterAt),
              value9: +Boolean(val.Listed),
              value10: val.registerAddress,
              value13: val.Website,
            };
          }
        } else if ([5, 6, 7].includes(this.trackName.ID)) {
          if (this.origin.Recommendation) {
            const detail = this.origin || {},
              user = detail.User || {},
              companyRecommend = user.Recommendation || {},
              recommend = detail.Recommendation || {},
              expert = detail.Expert || {};

            temp = {
              Photo: transformAttachment(user.Photo),
              value: transformAttachment(val.Form),
              value0: detail.Name,
              value1: detail.CompanyName,
              value2: detail.Referrer,
              value3: moment(detail.Time),
              value4: user.Name,
              value5: user.Gender,
              value6: user.Identity,
              value7: user.Political,
              value8: user.Title,
              value9: user.Education,
              value10: user.Company,
              value10_1: user.Address,
              value10_2: companyRecommend.ZipCode,
              value11: user.Position,
              value12: user.WorkDuration,
              value13: user.Phone,
              value14: user.Email,
              value15: user.EduExp,
              value16: user.WorkExp,
              value17: user.SideJob,
              value18: user.Rewards,
              value18_1: user.Contributions,
              value18_2: user.Achievements,
              value19: companyRecommend.Contact,
              value20: companyRecommend.Phone,
              value21: companyRecommend.Email,
              value22: companyRecommend.Comment,
              value22_1: transformAttachment(user.Attachments) || [],
              value23: recommend.Name,
              value24: recommend.Contact,
              value25: recommend.Phone,
              value26: recommend.Fax,
              value27: recommend.Address,
              value28: recommend.ZipCode,
              value28_1: recommend.Email,
              value29: recommend.Intro,
              value30: recommend.Comment,
              value31: expert.Name,
              value32: expert.Department,
              value33: expert.Position,
              value34: expert.Address,
              value35: expert.ZipCode,
              value36: expert.Intro,
              value37: expert.Comment,
            };
          } else if (this.track) {
            const detail = val.Detail || {},
              user = detail.User || {},
              companyRecommend = user.Recommendation || {},
              recommend = detail.Recommendation || {},
              expert = detail.Expert || {};

            temp = {
              Photo: transformAttachment(user.Photo),
              value: transformAttachment(val.Form),
              value0: detail.Name,
              value1: detail.CompanyName,
              value2: detail.Referrer,
              value3: moment(detail.Time),
              value4: user.Name,
              value5: user.Gender,
              value6: user.Identity,
              value7: user.Political,
              value8: user.Title,
              value9: user.Education,
              value10: user.Company,
              value10_1: user.Address,
              value10_2: companyRecommend.ZipCode,
              value11: user.Position,
              value12: user.WorkDuration,
              value13: user.Phone,
              value14: user.Email,
              value15: user.EduExp,
              value16: user.WorkExp,
              value17: user.SideJob,
              value18: user.Rewards,
              value18_1: user.Contributions,
              value18_2: user.Achievements,
              value19: companyRecommend.Contact,
              value20: companyRecommend.Phone,
              value21: companyRecommend.Email,
              value22: companyRecommend.Comment,
              value22_1: transformAttachment(user.Attachments) || [],
              value23: recommend.Name,
              value24: recommend.Contact,
              value25: recommend.Phone,
              value26: recommend.Fax,
              value27: recommend.Address,
              value28: recommend.ZipCode,
              value28_1: recommend.Email,
              value29: recommend.Intro,
              value30: recommend.Comment,
              value31: expert.Name,
              value32: expert.Department,
              value33: expert.Position,
              value34: expert.Address,
              value35: expert.ZipCode,
              value36: expert.Intro,
              value37: expert.Comment,
            };
          } else if (hasWrire2) {
            const detail = hasWrire2.Detail || {},
              user = detail.User || {},
              companyRecommend = user.Recommendation || {};
            temp = {
              value1: detail.CompanyName,
              value10: user.Company,
              value10_1: user.Address,
              value10_2: companyRecommend.ZipCode,
              value22_1: [],
            };
          } else if (this.company) {
            temp = {
              value: transformAttachment(val.Form),
              value1: val.companyName,
              value10: val.companyName,
              value22_1: [],
            };
          }
        }
        this.Form = temp.value;
        this.current = temp;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>