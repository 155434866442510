<template>
  <div class="text-left p-20">
    <div class="mb-40">
      <a-form-model
        ref="container1"
        :model="model"
        :labelCol="{ span: 7 }"
        :wrapperCol="{ span: 11 }"
        labelAlign="left"
        :colon="false"
      >
        <!-- 21 -->
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>附件</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="value1"
              label="申报表（请上传盖章扫描pdf版本）"
              :rules="[
                {
                  required: true,
                  validator: validator,
                  message: '请上传',
                  trigger: ['change'],
                },
              ]"
            >
              <div class="relative">
                <file-upload
                  :multiple="false"
                  :size="20"
                  accept=".pdf"
                  v-model="model.value1"
                  class="mr-20"
                ></file-upload>
                <a
                  @click="print"
                  class="
                    text-sm text-primary
                    absolute
                    left-0
                    top-0
                    transform
                    translate-x-1/2
                  "
                  >下载2021“工赋风云榜”年度风云企业申报表</a
                >
              </div>
            </gf-form-item>
          </div>
        </div>
      </a-form-model>
    </div>
    <div class="text-center">
      <slot v-bind="{ validate }" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: {},
    };
  },
  created() {
    this.model = {
      value1: this.dataSource.Form ? [this.dataSource.Form] : [],
    };
  },
  computed: {
    str() {
      return `<div style="width: 100%">
      <div style="min-height: 978px; page-break-after: always; font-wight: 300">
        <div style="text-align: end">编号：_____________</div>
        <h1
          style="margin: 200px 0;text-align: center;font-weight: 700; font-size: 36px; padding: 0 100px"
        >
          2021“工赋风云榜”年度风云企业 申报表
        </h1>
        <div style="text-align: center">
        <div style="margin: 0 auto; display: inline-block; text-align: start">
          <div style="margin-bottom: 10px">申报单位（盖章）：${
            this.dataSource.Detail.CompanyName || ""
          }</div>
          <div style="margin-bottom: 10px">推荐机构：${
            this.dataSource.Detail.Referrer || ""
          }</div>
          <div style="margin-bottom: 10px">申报日期：${
            moment(this.dataSource.Detail.Time).format("YYYY-MM-DD") || ""
          }</div>
        </div>
        </div>
        
      </div>
      <div style="min-height: 978px; page-break-after: always;font-weight: 300">
        <div style="font-size: 24px; font-weight: 500; text-align: center; margin-bottom: 100px">填报说明</div>
        <p style="text-indent: 2em; line-height: 36px !important; margin-bottom: 10px">
          1、“工赋风云榜”年度风云企业评选活动申报材料由申报表和证实性材料两部分组成，所填数据及提供资料务必真实、准确，数字及各类符号应填写正确、清楚、完整。本表封面编号由“工赋风云榜”评选活动组织委员会统一填写。
        </p>
        <p style="text-indent: 2em; line-height: 36px !important; margin-bottom: 10px">2、证实性材料包括不限于客户见证、知识产权或专利证书、介绍视频，以复印件形式附在申报表后。</p>
        <p style="text-indent: 2em; line-height: 36px !important; margin-bottom: 10px">3、申报单位请登陆工赋风云榜评选网站（https://events.gongfuxingqiu.com/fyb2021）进行注册以及申报表的填写，并且上传PDF盖公章扫描版一份。</p>
        <p style="text-indent: 2em; line-height: 36px !important; margin-bottom: 10px">4、纸质盖章版打印4份，快递或闪送到虹口区东大名路1098号浦江国际金融广场3楼上海市工业互联网协会，收件人：刘翊尊，13838160215</p>
        <p style="text-indent: 2em; line-height: 36px !important; margin-bottom: 10px">5、填写过程中有问题可以咨询工赋骑士刘翊尊，13838160215</p>
      </div>
      <div style="min-height: 978px; page-break-after: always">
        <div style="font-weight: 400; margin-bottom: 10px">一、企业基本情况</div>
        <table style="border-collapse: collapse; width: 99.7768%; text-align: center; height: 100%; font-weight: 300; font-size: 16px;line-height: 1.5" border="1">
          <tbody style="height: 100%">
            <tr style="height:50px;page-break-inside: avoid;">
              <td style="width: 15.8167%" colspan="2">参选赛道</td>
              <td style="width: 7.90836%" colspan="8">
                <input type="checkbox" ${
                  this.dataSource.Detail.Track.ID === 1 ? "checked" : ""
                } /> 领军企业
                <input type="checkbox" ${
                  this.dataSource.Detail.Track.ID === 2 ? "checked" : ""
                } /> 自主创新
                <input type="checkbox" ${
                  this.dataSource.Detail.Track.ID === 3 ? "checked" : ""
                } /> 工赋新锐
                <input type="checkbox" ${
                  this.dataSource.Detail.Track.ID === 4 ? "checked" : ""
                } /> 公共服务
              </td>
            </tr>
            <tr style="height:50px;page-break-inside: avoid;">
              <td style="width: 15.8167%;height:50px" colspan="2">企业名称（全称）</td>
              <td style="width: 7.90836%;height:50px" colspan="3">${
                this.dataSource.Detail.Company.Name || ""
              }</td>
              <td style="width: 7.90836%;height:50px" colspan="2">成立时间</td>
              <td style="width: 7.90836%;height:50px" colspan="3">${
                moment(this.dataSource.Detail.Company.RegisterTime).format(
                  "YYYY-MM-DD"
                ) || ""
              }</td>
            </tr>
            <tr style="height:50px;page-break-inside: avoid;">
              <td style="width: 15.8167%" colspan="2">企业性质</td>
              <td style="width: 7.90836%" colspan="8">
                <span>
                <input type="checkbox" ${
                  this.dataSource.Detail.Company.Property.ID === 8
                    ? "checked"
                    : ""
                } /> 国家机关
                </span>
                <span>
                <input type="checkbox" ${
                  this.dataSource.Detail.Company.Property.ID === 5
                    ? "checked"
                    : ""
                } /> 事业单位（含高校及研究机构）
                </span>
                <span>
                <input type="checkbox" ${
                  this.dataSource.Detail.Company.Property.ID === 9
                    ? "checked"
                    : ""
                } /> 社会团体
                </span>
                <span>
                <input type="checkbox" ${
                  this.dataSource.Detail.Company.Property.ID === 1
                    ? "checked"
                    : ""
                } /> 国有企业
                </span>
                <span>
                <input type="checkbox" ${
                  this.dataSource.Detail.Company.Property.ID === 2
                    ? "checked"
                    : ""
                } /> 民营企业
                </span>
                <span>
                <input type="checkbox" ${
                  this.dataSource.Detail.Company.Property.ID === 4
                    ? "checked"
                    : ""
                } /> 外资企业
                </span>
              </td>
            </tr>
            <tr style="height:50px;page-break-inside: avoid;">
              <td style="width: 15.8167%" colspan="2">企业规模</td>
              <td style="width: 7.90836%" colspan="3">
                <input type="checkbox" ${
                  this.dataSource.Detail.Company.Scale.ID === 1 ? "checked" : ""
                } /> 大型
                <input type="checkbox" ${
                  this.dataSource.Detail.Company.Scale.ID === 2 ? "checked" : ""
                } /> 中型
                <input type="checkbox" ${
                  this.dataSource.Detail.Company.Scale.ID === 3 ? "checked" : ""
                } /> 小型
              </td>
              <td style="width: 7.90836%" colspan="2">员工人数</td>
              <td style="width: 7.90836%" colspan="3">${
                this.dataSource.Detail.Company.Employees || ""
              }</td>
            </tr>
            <tr style="height:50px;page-break-inside: avoid;">
              <td style="width: 15.8167%" colspan="2">是否上市</td>
              <td style="width: 7.90836%" colspan="3">
                <input type="checkbox" ${
                  this.dataSource.Detail.Company.Listed ? "checked" : ""
                } /> 是
                <input type="checkbox" ${
                  !this.dataSource.Detail.Company.Listed ? "checked" : ""
                } /> 否
              </td>
              <td style="width: 7.90836%" colspan="2">注册地/总部所在地</td>
              <td style="width: 7.90836%" colspan="3">${
                this.dataSource.Detail.Company.RegisterAddress || ""
              }</td>
            </tr>
            <tr style="height:50px;page-break-inside: avoid;">
              <td style="width: 15.8167%" colspan="2">
                <div>2020年营业额</div>
              </td>
              <td style="width: 7.90836%" colspan="3">${
                this.dataSource.Detail.Company.Revenue2020
                  ? this.dataSource.Detail.Company.Revenue2020 / 10000 + "万"
                  : ""
              }</td>
              <td style="width: 7.90836%" colspan="2">
                <div>2021年预估营业额</div>
              </td>
              <td style="width: 7.90836%" colspan="3">${
                this.dataSource.Detail.Company.Revenue2021
                  ? this.dataSource.Detail.Company.Revenue2021 / 10000 + "万"
                  : ""
              }</td>
            </tr>
            <tr style="height:50px;page-break-inside: avoid;">
              <td style="width: 15.8167%" colspan="2">企业官网</td>
              <td style="width: 7.90836%" colspan="8">${
                this.dataSource.Detail.Company.Website || ""
              }</td>
            </tr>
            <tr style="height:100px;page-break-inside: avoid;">
              <td style="width: 15.8167%" colspan="2">产品及主营业务</td>
              <td style="width: 7.90836%; text-align: start;padding: 10px" colspan="8">${
                this.dataSource.Detail.Company.Product
                  ? this.dataSource.Detail.Company.Product.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""
              }</td>
            </tr>
            <tr style="height:100px;page-break-inside: avoid;">
              <td style="width: 15.8167%" colspan="2">融资历程</td>
              <td style="width: 7.90836%; text-align: start;padding: 10px" colspan="8">${
                this.dataSource.Detail.Company.Financing
                  ? this.dataSource.Detail.Company.Financing.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""
              }</td>
            </tr>
            <tr style="height:50px;page-break-inside: avoid;">
              <td style="width: 7.90836%" colspan="2" rowspan="2">联系人</td>
              <td style="width: 7.90836%">姓名</td>
              <td style="width: 7.90836%" colspan="2">${
                this.dataSource.Detail.Company.Contact.Name || ""
              }</td>
              <td style="width: 7.90836%">手机</td>
              <td style="width: 7.90836%" colspan="4">${
                this.dataSource.Detail.Company.Contact.Phone || ""
              }</td>
            </tr>
            <tr style="height:50px">
              <td style="width: 7.90836%">职务</td>
              <td style="width: 7.90836%" colspan="2">${
                this.dataSource.Detail.Company.Contact.Position || ""
              }</td>
              <td style="width: 7.90836%">邮箱</td>
              <td style="width: 7.90836%" colspan="4">${
                this.dataSource.Detail.Company.Contact.Email || ""
              }</td>
            </tr>
            <tr style="height:50px;page-break-inside: avoid;">
              <td style="width: 7.90836%" colspan="10">
                <div style="text-align:start">自荐/推荐理由:</div>
                <div style=" text-align: start;padding: 10px;">${
                  this.dataSource.Detail.Company.Reason
                    ? this.dataSource.Detail.Company.Reason.replace(
                        / /g,
                        "&nbsp;"
                      ).replace(/\n/g, "<br />")
                    : ""
                }</div>
              </td>
            </tr>
            <tr style="height:50px;page-break-inside: avoid;">
              <td style="width: 7.90836%" colspan="10">
                <div style="text-align:start">方案内容:</div>
                <div style=" text-align: start;padding: 10px;">${
                  this.dataSource.Detail.Company.Plan
                    ? this.dataSource.Detail.Company.Plan.replace(
                        / /g,
                        "&nbsp;"
                      ).replace(/\n/g, "<br />")
                    : ""
                }</div>
              </td>
            </tr>
            <tr style="opacity:0;border:none;height:0">
              <td style="width: 7.90836%"></td>
              <td style="width: 7.90836%"></td>
              <td style="width: 7.90836%"></td>
              <td style="width: 7.90836%"></td>
              <td style="width: 7.90836%"></td>
              <td style="width: 7.90836%"></td>
              <td style="width: 7.90836%"></td>
              <td style="width: 7.90836%"></td>
              <td style="width: 7.90836%"></td>
              <td style="width: 7.91053%"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="min-height: 978px; page-break-after: always;font-weight: 300"">
        <div style="font-weight: 400; margin-bottom: 10px">二、主管部门/行业协会推荐意见（如有）</div>
        <table
          style="border-collapse: collapse; width: 99.7768%; border-color: #000; height: 100%; text-align:center;font-weight: 300; font-size: 16px;line-height: 1.5"
          border="1"
        >
          <tbody style="height: 100%">
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%;style="height: 50px"">主管部门/行业协会名称</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Recommendation.Name || ""
              }</td>
            </tr>
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%">联系电话及联系人</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Recommendation.Contact || ""
              }${
        this.dataSource.Detail.Recommendation.Contact &&
        this.dataSource.Detail.Recommendation.Phone
          ? " - "
          : ""
      }${this.dataSource.Detail.Recommendation.Phone || ""}</td>
            </tr>
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%">联系传真及E-mail</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Recommendation.Fax || ""
              }${
        this.dataSource.Detail.Recommendation.Fax &&
        this.dataSource.Detail.Recommendation.Email
          ? " - "
          : ""
      }${this.dataSource.Detail.Recommendation.Email || ""}</td>
            </tr>
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%">联系地址及邮编</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Recommendation.Address || ""
              }${
        this.dataSource.Detail.Recommendation.Address &&
        this.dataSource.Detail.Recommendation.ZipCode
          ? " - "
          : ""
      }${this.dataSource.Detail.Recommendation.ZipCode || ""}</td>
            </tr>
            <tr style="height: 300px;page-break-inside: avoid;">
              <td style="width: 36%;">
                <div>主管部门/行业协会简介</div>
              </td>
              <td style="width: 64%; text-align: start;padding: 10px;height: 200px">${
                this.dataSource.Detail.Recommendation.Intro
                  ? this.dataSource.Detail.Recommendation.Intro.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""
              }</td>
            </tr>
            <tr style="height: 500px;page-break-inside: avoid;">
              <td style="width: 36%">推荐意见</td>
              <td style="width: 64%;font-size: 16px;line-height: 1.5">
                <div style="min-height:70%;text-align: start;padding: 10px">${
                  this.dataSource.Detail.Recommendation.Comment
                    ? this.dataSource.Detail.Recommendation.Comment.replace(
                        / /g,
                        "&nbsp;"
                      ).replace(/\n/g, "<br />")
                    : ""
                }</div>
                <div style="margin-bottom:10px">
                    <div style="width:70%;text-align: end">主管部门/行业协会（盖章）：</div>
                </div>
                <div style="margin-bottom:10px">
                    <div style="width:70%;text-align: end">负责人（签字）：</div>
                </div>
                <div style="justify-content: end;display: flex;padding-right:20px">
                    <div style="width: 10%"></div>年
                    <div style="width: 10%"></div>月
                    <div style="width: 10%"></div>日
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="min-height: 978px; page-break-after: always;font-weight: 300">
        <div style="font-weight: 400;margin-bottom: 10px">三、专家推荐意见（如有）</div>
        <table
          style="border-collapse: collapse; width: 99.7768%; border-color: #000; height: 100%; text-align:center;font-weight: 300; font-size: 16px;line-height: 1.5"
          border="1"
        >
          <tbody style="height: 100%">
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%">专家姓名</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Expert.Name || ""
              }</td>
            </tr>
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%">单位及职位</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Expert.Department || ""
              }${
        this.dataSource.Detail.Expert.Department &&
        this.dataSource.Detail.Expert.Position
          ? " - "
          : ""
      }${this.dataSource.Detail.Expert.Position || ""}</td>
            </tr>
            <tr style="height: 50px;page-break-inside: avoid;">
              <td style="width: 36%">联系地址及邮编</td>
              <td style="width: 64%">${
                this.dataSource.Detail.Expert.Address || ""
              }${
        this.dataSource.Detail.Expert.Address &&
        this.dataSource.Detail.Expert.ZipCode
          ? " - "
          : ""
      }${this.dataSource.Detail.Expert.ZipCode || ""}</td>
            </tr>
            <tr style="height: 300px;page-break-inside: avoid;">
              <td style="width: 36%">
                <div>专家个人介绍</div>
              </td>
              <td style="width: 64%;text-align: start;padding: 10px">${
                this.dataSource.Detail.Expert.Intro
                  ? this.dataSource.Detail.Expert.Intro.replace(
                      / /g,
                      "&nbsp;"
                    ).replace(/\n/g, "<br />")
                  : ""
              }</td>
            </tr>
            <tr style="height: 500px;page-break-inside: avoid;">
              <td style="width: 36%">推荐意见</td>
              <td style="width: 64%;font-size: 16px;line-height: 1.5">
                <div style="height:80%;text-align: start;padding: 10px">${
                  this.dataSource.Detail.Expert.Comment
                    ? this.dataSource.Detail.Expert.Comment.replace(
                        / /g,
                        "&nbsp;"
                      ).replace(/\n/g, "<br />")
                    : ""
                }</div>
                <div style="margin-bottom:10px">
                    <div style="width:70%;text-align: end">专家（签字）：</div>
                </div>
                <div style="justify-content: end;display: flex;padding-right:20px">
                    <div style="width: 10%"></div>年
                    <div style="width: 10%"></div>月
                    <div style="width: 10%"></div>日
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>`;
    },
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.container1.validate((valid) => {
          const data = this.model.value1.filter((item) => item);
          if (valid && data.length && data[0].ID) {
            resolve({ ...this.model.value1[0] });
          } else {
            reject();
          }
        });
      });
    },
    print() {
      const el = this.str;
      const iframe = document.createElement("IFRAME");
      window.document.body.append(iframe);
      iframe.setAttribute(
        "style",
        "position: absolute;left:-1000px;top:-1000px"
      );
      const doc = iframe.contentDocument;

      const info = this.$store.state.userInfo;
      const company = this.$store.state.companyInfo;
      const detail = this.dataSource.Detail || {};
      const temp = {
        uid: info.UID || info.id,
        type: "click",
        name: "download",
        metadata: {
          company: company.companyName,
          track: detail.Track.Name,
        },
      };
      this.$store.dispatch("track/setTrack", temp);

      doc.write(el);
      doc.close();
      iframe.contentWindow.focus();
      const title = window.document.title;
      window.document.title = "2021“工赋风云榜”年度风云企业申报表";
      iframe.contentWindow.print();
      window.document.title = title;
      window.document.body.removeChild(window.document.querySelector("iframe"));
    },
    validator(rule, value, callback) {
      callback();
    },
  },
};
</script>