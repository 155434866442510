var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left p-20"},[_c('div',{staticClass:"mb-40"},[_c('a-form-model',{ref:"container",attrs:{"model":_vm.model,"labelCol":{ span: 5 },"wrapperCol":{ span: 11 },"labelAlign":"left","colon":false}},[_c('div',{staticClass:"mb-40"},[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("申报基础信息")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"value0","label":"申报人姓名","wrapperCol":{ span: 7 },"rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value0),callback:function ($$v) {_vm.$set(_vm.model, "value0", $$v)},expression:"model.value0"}})],1),_c('gf-form-item',{attrs:{"prop":"value1","label":"所在单位","wrapperCol":{ span: 7 },"rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value1),callback:function ($$v) {_vm.$set(_vm.model, "value1", $$v)},expression:"model.value1"}})],1),_c('gf-form-item',{attrs:{"prop":"value2","label":"推荐机构","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value2),callback:function ($$v) {_vm.$set(_vm.model, "value2", $$v)},expression:"model.value2"}})],1),_c('gf-form-item',{attrs:{"prop":"value3","label":"申报日期","wrapperCol":{ span: 7 },"rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            }}},[_c('a-date-picker',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value3),callback:function ($$v) {_vm.$set(_vm.model, "value3", $$v)},expression:"model.value3"}})],1)],1)]),_c('div',{staticClass:"mb-40"},[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("个人基本情况")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"Photo","label":"登记照（1寸免冠证件照）","wrapperCol":{ span: 7 },"rules":[
              {
                required: true,
                message: '请上传',
                trigger: ['change', 'blur'],
              },
              { validator: _vm.validator } ]}},[_c('picture-upload',{attrs:{"scale":{ w: 413, h: 626 },"width":280,"height":160,"accept":".png,.jpg,.jpeg","size":5},model:{value:(_vm.model.Photo),callback:function ($$v) {_vm.$set(_vm.model, "Photo", $$v)},expression:"model.Photo"}})],1),_c('gf-form-item',{attrs:{"prop":"value4","label":"姓名","wrapperCol":{ span: 7 },"rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value4),callback:function ($$v) {_vm.$set(_vm.model, "value4", $$v)},expression:"model.value4"}})],1),_c('gf-form-item',{attrs:{"prop":"value5","label":"性别","wrapperCol":{ span: 7 },"rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            }}},[_c('a-select',{model:{value:(_vm.model.value5),callback:function ($$v) {_vm.$set(_vm.model, "value5", $$v)},expression:"model.value5"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v("男")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v("女")])],1)],1),_c('gf-form-item',{attrs:{"prop":"value6","label":"身份证","wrapperCol":{ span: 7 },"rules":[
              {
                validator: _vm.validateId,
                message: '请输入正确格式的身份证',
                trigger: ['change', 'blur'],
              },
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              } ]}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value6),callback:function ($$v) {_vm.$set(_vm.model, "value6", $$v)},expression:"model.value6"}})],1),_c('gf-form-item',{attrs:{"prop":"value7","label":"政治面貌","wrapperCol":{ span: 7 },"rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value7),callback:function ($$v) {_vm.$set(_vm.model, "value7", $$v)},expression:"model.value7"}})],1),_c('gf-form-item',{attrs:{"prop":"value8","rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            },"label":"职称","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value8),callback:function ($$v) {_vm.$set(_vm.model, "value8", $$v)},expression:"model.value8"}})],1),_c('gf-form-item',{attrs:{"prop":"value9","label":"学历学位","wrapperCol":{ span: 7 },"rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value9),callback:function ($$v) {_vm.$set(_vm.model, "value9", $$v)},expression:"model.value9"}})],1),_c('gf-form-item',{attrs:{"prop":"value10","label":"所在单位","wrapperCol":{ span: 7 },"rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value10),callback:function ($$v) {_vm.$set(_vm.model, "value10", $$v)},expression:"model.value10"}})],1),_c('gf-form-item',{attrs:{"prop":"value10_1","label":"单位地址","wrapperCol":{ span: 7 },"rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value10_1),callback:function ($$v) {_vm.$set(_vm.model, "value10_1", $$v)},expression:"model.value10_1"}})],1),_c('gf-form-item',{attrs:{"prop":"value10_2","label":"单位邮编","wrapperCol":{ span: 7 },"rules":[
              {
                validator: _vm.validateZip,
                message: '请输入正确格式的邮政编码',
                trigger: ['change', 'blur'],
              },
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              } ]}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value10_2),callback:function ($$v) {_vm.$set(_vm.model, "value10_2", $$v)},expression:"model.value10_2"}})],1),_c('gf-form-item',{attrs:{"prop":"value11","label":"担任职务","wrapperCol":{ span: 7 },"rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value11),callback:function ($$v) {_vm.$set(_vm.model, "value11", $$v)},expression:"model.value11"}})],1),_c('gf-form-item',{attrs:{"prop":"value12","label":"现岗位或相似岗位工作年限","wrapperCol":{ span: 7 },"rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            }}},[_c('a-input-number',{attrs:{"precision":0,"disabled":_vm.disabled},model:{value:(_vm.model.value12),callback:function ($$v) {_vm.$set(_vm.model, "value12", $$v)},expression:"model.value12"}})],1),_c('gf-form-item',{attrs:{"prop":"value13","label":"手机","wrapperCol":{ span: 7 },"rules":[
              {
                validator: _vm.validateTel,
                message: '请输入正确格式的手机号码',
                trigger: ['change', 'blur'],
              },
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              } ]}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value13),callback:function ($$v) {_vm.$set(_vm.model, "value13", $$v)},expression:"model.value13"}})],1),_c('gf-form-item',{attrs:{"prop":"value14","label":"邮箱","wrapperCol":{ span: 7 },"rules":[
              {
                validator: _vm.validateEmail,
                message: '请输入正确格式的邮箱',
                trigger: ['change', 'blur'],
              },
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              } ]}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value14),callback:function ($$v) {_vm.$set(_vm.model, "value14", $$v)},expression:"model.value14"}})],1),_c('gf-form-item',{attrs:{"prop":"value15","rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
              {
                max: 300,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              } ],"label":"教育经历"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"填写示例：2012-2016-北京大学"},model:{value:(_vm.model.value15),callback:function ($$v) {_vm.$set(_vm.model, "value15", $$v)},expression:"model.value15"}})],1),_c('gf-form-item',{attrs:{"prop":"value16","rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
              {
                max: 300,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              } ],"label":"工作经历"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"请在此输入工作经历"},model:{value:(_vm.model.value16),callback:function ($$v) {_vm.$set(_vm.model, "value16", $$v)},expression:"model.value16"}})],1),_c('gf-form-item',{attrs:{"prop":"value17","rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
              {
                max: 300,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              } ],"label":"社会团体兼职情况"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"请在此输入社会团体兼职情况"},model:{value:(_vm.model.value17),callback:function ($$v) {_vm.$set(_vm.model, "value17", $$v)},expression:"model.value17"}})],1),_c('gf-form-item',{attrs:{"prop":"value18","rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
              {
                max: 300,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              } ],"label":"何时何地受过何种奖励"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":""},model:{value:(_vm.model.value18),callback:function ($$v) {_vm.$set(_vm.model, "value18", $$v)},expression:"model.value18"}})],1),_c('gf-form-item',{attrs:{"label":"证实性材料","tooltip":"不限于个人技术资格证书、相关技术研究或项目成果、个人获得国家及省部级以上荣誉等"}},[(_vm.model.value22_1)?_c('file-upload',{attrs:{"size":100},model:{value:(_vm.model.value22_1),callback:function ($$v) {_vm.$set(_vm.model, "value22_1", $$v)},expression:"model.value22_1"}}):_vm._e()],1)],1)]),_c('div',{staticClass:"mb-40"},[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("申报说明")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"value18_1","rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
              {
                max: 3000,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              } ],"label":"工作成绩和突出贡献"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":3000,"placeholder":"结合所申报的奖项方向，展示个人在上海市数字化转型/工业互联网/技术创新等领域中的主要工作成绩和突出贡献。"},model:{value:(_vm.model.value18_1),callback:function ($$v) {_vm.$set(_vm.model, "value18_1", $$v)},expression:"model.value18_1"}})],1),_c('gf-form-item',{attrs:{"prop":"value18_2","rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
              {
                max: 3000,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              } ],"label":"代表性成果"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":3000,"placeholder":"说明个人取得的成果，如取得专利、攻克难题、获得团队荣誉、成果水平、特色特点等"},model:{value:(_vm.model.value18_2),callback:function ($$v) {_vm.$set(_vm.model, "value18_2", $$v)},expression:"model.value18_2"}})],1)],1)]),_c('div',{staticClass:"mb-40"},[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("所在单位推荐意见")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"value19","rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            },"label":"单位联系人","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value19),callback:function ($$v) {_vm.$set(_vm.model, "value19", $$v)},expression:"model.value19"}})],1),_c('gf-form-item',{attrs:{"prop":"value20","rules":[
              {
                validator: _vm.validateTel,
                message: '请输入正确格式的手机号码',
                trigger: ['change', 'blur'],
              },
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              } ],"label":"联系电话","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value20),callback:function ($$v) {_vm.$set(_vm.model, "value20", $$v)},expression:"model.value20"}})],1),_c('gf-form-item',{attrs:{"prop":"value21","rules":[
              {
                validator: _vm.validateEmail,
                message: '请输入正确格式的邮箱',
                trigger: ['change', 'blur'],
              },
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              } ],"label":"邮箱","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value21),callback:function ($$v) {_vm.$set(_vm.model, "value21", $$v)},expression:"model.value21"}})],1),_c('gf-form-item',{attrs:{"prop":"value22","rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
              {
                max: 300,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              } ],"label":"推荐意见"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"围绕申报人日常工作情况、工作业绩、申报材料真实性等展开"},model:{value:(_vm.model.value22),callback:function ($$v) {_vm.$set(_vm.model, "value22", $$v)},expression:"model.value22"}})],1)],1)]),_c('div',[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("主管部门/行业协会推荐意见")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"value23","label":"主管部门/行业协会名称","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value23),callback:function ($$v) {_vm.$set(_vm.model, "value23", $$v)},expression:"model.value23"}})],1),_c('gf-form-item',{attrs:{"prop":"value24","label":"联系人","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value24),callback:function ($$v) {_vm.$set(_vm.model, "value24", $$v)},expression:"model.value24"}})],1),_c('gf-form-item',{attrs:{"rules":{
              validator: _vm.validateTel,
              message: '请输入正确格式的手机号码',
              trigger: ['change', 'blur'],
            },"prop":"value25","label":"手机","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value25),callback:function ($$v) {_vm.$set(_vm.model, "value25", $$v)},expression:"model.value25"}})],1),_c('gf-form-item',{attrs:{"rules":{
              validator: _vm.validateFax,
              message: '请输入正确格式的传真',
              trigger: ['change', 'blur'],
            },"prop":"value26","label":"传真","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value26),callback:function ($$v) {_vm.$set(_vm.model, "value26", $$v)},expression:"model.value26"}})],1),_c('gf-form-item',{attrs:{"prop":"value27","label":"地址","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value27),callback:function ($$v) {_vm.$set(_vm.model, "value27", $$v)},expression:"model.value27"}})],1),_c('gf-form-item',{attrs:{"rules":{
              validator: _vm.validateZip,
              message: '请输入正确格式的邮政编码',
              trigger: ['change', 'blur'],
            },"prop":"value28","label":"邮编","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value28),callback:function ($$v) {_vm.$set(_vm.model, "value28", $$v)},expression:"model.value28"}})],1),_c('gf-form-item',{attrs:{"rules":{
              validator: _vm.validateEmail,
              message: '请输入正确格式的邮箱',
              trigger: ['change', 'blur'],
            },"prop":"value28_1","label":"邮箱","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value28_1),callback:function ($$v) {_vm.$set(_vm.model, "value28_1", $$v)},expression:"model.value28_1"}})],1),_c('gf-form-item',{attrs:{"prop":"value29","rules":[
              {
                max: 300,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              } ],"label":"主管部门/行业协会简介"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"请在此输入主管部门/行业协会简介"},model:{value:(_vm.model.value29),callback:function ($$v) {_vm.$set(_vm.model, "value29", $$v)},expression:"model.value29"}})],1),_c('gf-form-item',{attrs:{"prop":"value30","rules":[
              {
                max: 300,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              } ],"label":"推荐意见"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"围绕申报人相关成果、行业影响力等展开"},model:{value:(_vm.model.value30),callback:function ($$v) {_vm.$set(_vm.model, "value30", $$v)},expression:"model.value30"}})],1)],1)]),_c('div',[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("专家推荐意见")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"value31","label":"专家姓名","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value31),callback:function ($$v) {_vm.$set(_vm.model, "value31", $$v)},expression:"model.value31"}})],1),_c('gf-form-item',{attrs:{"prop":"value32","label":"所在单位","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value32),callback:function ($$v) {_vm.$set(_vm.model, "value32", $$v)},expression:"model.value32"}})],1),_c('gf-form-item',{attrs:{"prop":"value33","label":"职位","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value33),callback:function ($$v) {_vm.$set(_vm.model, "value33", $$v)},expression:"model.value33"}})],1),_c('gf-form-item',{attrs:{"prop":"value34","label":"地址","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value34),callback:function ($$v) {_vm.$set(_vm.model, "value34", $$v)},expression:"model.value34"}})],1),_c('gf-form-item',{attrs:{"rules":{
              validator: _vm.validateZip,
              message: '请输入正确格式的邮政编码',
              trigger: ['change', 'blur'],
            },"prop":"value35","label":"邮编","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.value35),callback:function ($$v) {_vm.$set(_vm.model, "value35", $$v)},expression:"model.value35"}})],1),_c('gf-form-item',{attrs:{"prop":"value36","rules":[
              {
                max: 300,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              } ],"label":"专家个人介绍"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"请在此输入专家个人简介"},model:{value:(_vm.model.value36),callback:function ($$v) {_vm.$set(_vm.model, "value36", $$v)},expression:"model.value36"}})],1),_c('gf-form-item',{attrs:{"prop":"value37","rules":[
              {
                max: 300,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              } ],"label":"推荐意见"}},[_c('gf-re-textarea',{attrs:{"disabled":_vm.disabled,"rows":5,"max":300,"placeholder":"围绕申报人相关成果、行业影响力等展开"},model:{value:(_vm.model.value37),callback:function ($$v) {_vm.$set(_vm.model, "value37", $$v)},expression:"model.value37"}})],1)],1)])])],1),_c('div',{staticClass:"text-center"},[_vm._t("default",null,null,{ validate: _vm.validate })],2)])}
var staticRenderFns = []

export { render, staticRenderFns }